import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Font} from '@react-pdf/renderer';
import {useProfileStudent} from "@hooks/useProfileStudent";
import logo from '../../assets/newpages/pdf/images/logo.svg';
import '../../assets/newpages/pdf/pdf.scss';
import logo2 from '../../assets/newpages/pdf/images/logo_vert1.svg';
import indicatorUser from '../../assets/newpages/pdf/images/indicator-user.jpg';
import achievementIcon1 from '../../assets/newpages/pdf/images/achievement-icon1.svg';
import achievementIcon2 from '../../assets/newpages/pdf/images/achievement-icon2.svg';
import achievementIcon3 from '../../assets/newpages/pdf/images/achievement-icon3.svg';
import achievementIcon4 from '../../assets/newpages/pdf/images/achievement-icon4.svg';
import achievementIcon5 from '../../assets/newpages/pdf/images/achievement-icon5.svg';
import {useSelector} from "react-redux";
import {NavLink, useParams} from "react-router-dom";
import BarChart from "@components/BarChart";
import PolarAreaChart from "@components/PolarAreaChart";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {DOWNLOAD_FILE} from "@api/endpoints";
import {usePDF} from "react-to-pdf";
import {displayDateDDMMYYYY} from "@utils/dateFormat";

const StudentPdf = ({}) => {
    const [loading,setLoading] = useState(false);
    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    const user = useSelector((state) => state.user.user);
    const { studentData } = useProfileStudent(user.id);
    const img = useDownloadFile(DOWNLOAD_FILE, user.avatarId).file;
    const avatar = studentData?.student?.newAvatar || img;
    const student = user;

    const rsvs = studentData.rsvs;

    const competenceChartData = studentData.competenceChartData;
    const competenceChartDataDop = studentData.competenceChartDataDop;
    const competenceTableDataOrderedByPoints = useMemo(
        () =>
            [...studentData.competenceTableData].sort(
                ({ points: pointsA }, { points: pointsB }) => pointsB - pointsA,
            ),
        [studentData.competenceTableData],
    );
    const competenceTableDataOrderedByPointsDop = useMemo(
        () =>
            [...studentData.competenceTableDataDop].sort(
                ({ points: pointsA }, { points: pointsB }) => pointsB - pointsA,
            ),
        [studentData.competenceTableDataDop],
    );
    const activityPoints = studentData.activityPoints?.map(el=>{
        return {
            name:el.activity.name,value:el.value,
            points:el.activityScore
        }
    });
    const [mainDpo,setMainDpo] = React.useState([]);
    const [secondaryDpo,setSecondaryDpo] = React.useState([]);
    useEffect(()=>{
        const main = studentData.dpos.filter(dpo => dpo.dpo.dpoType.type ==='MAIN');
        const digital = studentData.dpos.filter(dpo => dpo.dpo.dpoType.type ==='DIGITAL');
        setMainDpo(main);
        setSecondaryDpo(digital);
    },[studentData]);
    const achievements = studentData.achievements;
    const icons = [
        achievementIcon1,
        achievementIcon2,
        achievementIcon3,
        achievementIcon4,
        achievementIcon5,
    ];
    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
    const styles = StyleSheet.create({
        page: {
            fontFamily: "Roboto",
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
        }
    });
    /*if(student && user && achievements && avatar && competenceChartData
        && competenceChartDataDop && activityPoints && mainDpo && secondaryDpo && competenceTableDataOrderedByPointsDop
        && competenceTableDataOrderedByPoints
    )*/
    const load = () => {
        setLoading(true);
        toPDF();
        setTimeout(()=>{
            setLoading(false);
        },2000)
    }
    return (
        <div className="newStyle pdf-content">
            <div className="download-file-pdf">
                <NavLink to={"/profile"}>
                    <a className="btn-back">Назад</a>
                </NavLink>
                <button onClick={!loading ? ()=>load() :null} className={`btn ${(loading || !studentData || !student) ? 'disabled' : 'btn-arrow'}  btn-blue`}>
                    {loading ?
                        <span style={{width:20,height:20}} className="loader2"></span>
                        :
                        "Скачать PDF"
                    }
                </button>
            </div>
            <div className="pdf-container newStyle">

                <div className={"pdf newStyle"}>

                    <div ref={targetRef}>
                        <div className={"page"}>
                            <header>
                                <div className="container">
                                    <div className="flex-block">
                                        <img src={logo} alt="" className="logo"/>
                                        <span className="employer">Работодателю</span>
                                        <p className="header-title">Оценка надпрофессиональных навыков на основе данных с платформы
                                            ПолиКапитал, актуальных по состоянию на <span>{displayDateDDMMYYYY(new Date())}</span></p>
                                        <img style={{width:80}} src={logo2} alt="" className="logo2"/>
                                    </div>
                                </div>
                            </header>
                            <main>
                                <div className="container">
                                    <div className="info-block">
                                        <div className="indicator-user-block blks">
                                            <span className="title">Основная информация</span>
                                            <p>В данном блоке представлена базовая информация о студенте. Полезно для
                                                предварительного анализа академического и профессионального фокуса кандидата!</p>
                                            <div className="indicator-user-info">
                                                <img src={avatar} alt=""/>
                                                <p className="user-title">{user?.secondName} {user?.firstName} {user?.middleName}</p>
                                                <ul className="unstyled">
                                                    {student?.qualification?.name &&
                                                        <li><span>Уровень образования</span> {`${student?.qualification?.name} ${student?.course} курс`}</li>
                                                    }
                                                    {student?.institute?.name &&
                                                        <li><span>Институт</span> {`${student?.institute?.name}`}</li>
                                                    }
                                                    {student?.eduDirection?.name &&
                                                        <li><span>Направление</span> {`${student?.eduDirection?.name}`}</li>
                                                    }
                                                    {student?.eduProgram?.name &&
                                                        <li><span>Образовательная программа</span> {`${student?.eduProgram?.name}`}</li>
                                                    }
                                                </ul>
                                                <div className="tag-blk">
                                                    <span>Профессия</span>
                                                    <ul className="tag-choice active unstyled">
                                                        {user?.professions && user?.professions?.map((profession, index) =>(
                                                            <li key={index}>{profession.name}</li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-blk">
                                            <div className="achievement blks">
                                                <span className="title">Достижения</span>
                                                <p>Ниже можно ознакомиться с общими достижениями студента в разрезе направлений
                                                    деятельности</p>
                                                <div className="achievement-block">
                                                    {achievements?.map((achievement, index) =>
                                                        <div key={index} className="achievement-blk">
                                                            <span className="image"><img  src={icons[index] ? icons[index] : ""} alt="Победы"/></span>
                                                            <p>{achievement.achievement.name}</p>
                                                            <span>{achievement.achievementScore}</span>
                                                        </div>

                                                    )}

                                                </div>
                                            </div>
                                            <div className="blks">
                                                <span className="title">Области деятельности</span>
                                                <p>В блоке представлена информация о предпочтениях студента, его активности по
                                                    различным сферам. Большее количественное значение соответствует большей
                                                    результативности</p>
                                                <div id="progress-bars-container">
                                                    {activityPoints &&
                                                        <BarChart maxValue={100} dataset={activityPoints} barHeight={"5px"} labels={true}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="blks competencies">
                                                <span className="title">Компетенции платформы “Россия страна возможностей”</span>
                                                <p>В этом блоке представлены в табличном виде основные и дополнительные компетенции
                                                    в рамках РСВ. Эти навыки могут быть особенно важны для работы в условиях
                                                    стресса, в ситуациях, когда требуется активная коммуникация с коллегами,
                                                    клиентами или партнерами</p>
                                                <div className="table-blk tableDiv indicator-table">
                                                    <span>Основные компетенции</span>
                                                    <table className="tables">
                                                        <thead>
                                                        <tr>
                                                            <th>Компетенция</th>
                                                            <th style={{textAlign: "end"}}><span>Текущие баллы</span></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {rsvs?.map((el, index) => (
                                                            <tr key={index}>
                                                                <td>{el?.rsv?.name}</td>
                                                                <td>{el?.rsvMark?.rsvMark ?? 0}</td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                    {/*<h3>Дополнительные компетенции</h3>
                                                    <table className="tables">
                                                        <thead>
                                                        <tr>
                                                            <th>Компетенция</th>
                                                            <th><span>Текущие баллы</span></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {competenceTableDataOrderedByPointsDop?.map((el, index) => (
                                                            <tr key={index} className="line">
                                                                <td>{el?.name}</td>
                                                                <td>{el.points}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className="page">
                            <header>
                                <div className="container">
                                    <div className="flex-block">
                                        <img src={logo} alt="" className="logo"/>
                                        <span className="employer">Работодателю</span>
                                        <p className="header-title">Оценка надпрофессиональных навыков на основе данных с
                                            платформы ПолиКапитал, актуальных по состоянию на <span>22.09.2024</span></p>
                                        <img style={{width:80}} src={logo2} alt="" className="logo2"/>

                                    </div>
                                </div>
                            </header>
                            <main>
                                <div className="container">
                                    <div className="info-block info-block2">
                                        <div className="info-blk">
                                            <div className="blks">
                                                <span className="title">Матрица компетенций</span>
                                                <p>В этом блоке в графическом и табличном виде перечислены основные и дополнительные
                                                    компетенции студента. Эти данные дают представление о том, как кандидат может
                                                    справляться с рабочими задачами, требующими лидерских, коммуникативныхи
                                                    аналитических способностей</p>
                                                <div className="chart-block flex-block">
                                                    <div className="chart-blk blk1">
                                                        <span>Основные</span>
                                                        <div>
                                                            <PolarAreaChart
                                                                click={false}
                                                                labels={competenceChartData.labels}
                                                                datasets={competenceChartData.datasets}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="chart-blk blk2">
                                                        <span>Дополнительные</span>
                                                        <div>
                                                            <PolarAreaChart
                                                                click={false}
                                                                labels={competenceChartDataDop.labels}
                                                                datasets={competenceChartDataDop.datasets}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-blk tableDiv indicator-table">
                                                    <span>Основные компетенции</span>
                                                    <table className="tables">
                                                        <thead>
                                                        <tr>
                                                            <th>Компетенция</th>
                                                            <th style={{textAlign: "end"}}><span>Текущие баллы</span></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {competenceTableDataOrderedByPoints?.map((el, index) => (
                                                            <tr key={index} className="line">
                                                                <td>{el?.name}</td>
                                                                <td>{el.points}</td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                    <h3>Дополнительные компетенции</h3>
                                                    <table className="tables">
                                                        <thead>
                                                        <tr>
                                                            <th>Компетенция</th>
                                                            <th><span>Текущие баллы</span></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {competenceTableDataOrderedByPointsDop?.map((el, index) => (
                                                            <tr key={index} className="line">
                                                                <td>{el?.name}</td>
                                                                <td>{el.points}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="indicator-user-block blks">
                                            <span className="title">Дополнительное профессиональное образование</span>
                                            <p>В этом блоке перечислены основные и цифровые ДПО студента. Полезно для оценки
                                                профессиональной гибкости, стремления к повышению квалификации!</p>
                                            <div className="table-blk">
                                                <h3>Основные ДПО</h3>
                                                <table className="tables tables-dpo">
                                                    <thead>
                                                    <tr>
                                                        <th>Название ДПО</th>
                                                        <th style={{textAlign:"end"}}>Дата</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mainDpo && mainDpo?.map(dpo => (
                                                        <tr>
                                                            <td>{dpo.dpo.name}</td>
                                                            <td>{dpo.year}</td>
                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                                <h3>Цифровые ДПО</h3>
                                                <table className="tables tables-dpo">
                                                    <thead>
                                                    <tr>
                                                        <th>Название ДПО</th>
                                                        <th style={{textAlign:"end"}}>Дата</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {secondaryDpo?.map(dpo => (
                                                        <tr>
                                                            <td>{dpo.dpo.name}</td>
                                                            <td>{dpo.year}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )


}

export default StudentPdf;
